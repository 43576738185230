@import '@/modules/ui/utils/mixins';

.recaptchaContainer {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(16px);
}

.deactivateButton {
  width: fit-content;
}

.deleteLink {
  padding: 0;

  text-align: left;

  &:hover {
    color: var(--mui-palette-secondary-main);

    background: transparent;
  }
}
