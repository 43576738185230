/* stylelint-disable selector-class-pattern */
@import '@/modules/ui/utils/mixins';

.dialog {
  @include medium-up {
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.MuiDialog-paperWidthSm) {
      width: px-to-rem(500px);
    }
  }
}

.closeButton {
  position: absolute;
  top: px-to-rem(6px);
  right: px-to-rem(16px);
}

.tabList {
  border-bottom: px-to-rem(2px) solid var(--mui-palette-grey-300);
}

.tabPanel {
  padding: px-to-rem(32px) 0;
}
