@import '@/modules/ui/utils/mixins';

.content {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(32px);
  align-items: center;

  width: 100%;

  @include medium-up {
    margin-top: 5rem;
  }
}

.header {
  width: 100%;

  font-size: px-to-rem(18px);
  text-align: center;

  @include medium-up {
    font-size: px-to-rem(20px);
  }
}
