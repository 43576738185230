@import '@/modules/ui/utils/mixins';

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: px-to-rem(30px) 0 px-to-rem(50px);

  .card {
    @include medium-up {
      width: min(100%, px-to-rem(650px));
      padding: px-to-rem(40px) px-to-rem(60px);
    }
  }
}

.titleContainer {
  width: min(100%, px-to-rem(450px));

  color: var(--mui-palette-common-white);
  text-align: center;
}

.title {
  margin: 0 0 px-to-rem(15px);

  font-weight: bold;
  font-size: px-to-rem(30px);
  line-height: 1.5;
}

.subtitle {
  margin: 0 0 px-to-rem(30px);

  font-weight: normal;
  font-size: px-to-rem(16px);

  @include medium-up {
    font-size: px-to-rem(20px);
    line-height: 1.4;
  }
}

.loginContainer {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(8px);
  align-items: center;

  margin-top: px-to-rem(50px);

  color: var(--mui-palette-common-white);

  p {
    margin: 0;
  }
}

.loginButton {
  padding: px-to-rem(8px) px-to-rem(24px);

  font-weight: bold;

  &:hover {
    background-color: rgb(0 0 0 / 15%);
  }
}
