@import '@/modules/ui/utils/mixins';

.recaptchaContainer {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(24px);

  margin: px-to-rem(16px) 0;
}

.requestButton {
  width: fit-content;

  font-weight: bold;
}
