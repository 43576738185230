.contained {
  --loading-color: var(--variant-containedColor);
  --loading-background-color: var(--variant-containedBg);
  --loading-box-shadow: var(--mui-shadows-2);
}

.text {
  --loading-color: var(--variant-textColor);
  --loading-background-color: var(--variant-textBg);
  --loading-box-shadow: none;
}

.outlined {
  --loading-color: var(--variant-outlinedColor);
  --loading-background-color: var(--variant-outlinedBg);
  --loading-box-shadow: none;
  --loading-border: 1px solid var(--variant-outlinedBorderColor, currentColor);
}

.loading {
  --mui-palette-action-disabled: var(--loading-color);
  --mui-palette-action-disabledBackground: var(--loading-background-color);
  border: var(--loading-border);

  box-shadow: var(--loading-box-shadow);
}
