@import '@/modules/ui/utils/mixins';

.mainAlert {
  position: relative;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: px-to-rem(16px);
}
