@import '@/modules/ui/utils/mixins';

.content {
  position: relative;

  margin: auto;
  padding: px-to-rem(30px) 0 px-to-rem(50px);

  color: var(--mui-palette-common-white);
  text-align: center;
}

.title {
  margin: 0 0 px-to-rem(15px);

  font-weight: bold;
  font-size: px-to-rem(30px);
  line-height: 1.5;
}

.subtitle {
  margin: 0 0 px-to-rem(26px);

  font-weight: normal;
  font-size: px-to-rem(16px);

  @include medium-up {
    font-size: px-to-rem(20px);
    line-height: 1.3;
  }
}

.cards {
  display: flex;
  flex-flow: column nowrap;
  gap: px-to-rem(25px);
  justify-content: center;
  align-items: center;

  @include medium-2-up {
    flex-direction: row;
    gap: 0;
  }
}

.separator {
  display: none;

  margin: 0 px-to-rem(55px);

  color: var(--mui-palette-common-white);
  font-size: px-to-rem(30px);
  line-height: 1;

  @include medium-2-up {
    display: flex;
    flex-direction: column;
    gap: px-to-rem(10px);
    align-items: center;
  }

  &::before,
  &::after {
    content: '';

    width: px-to-rem(3px);
    height: px-to-rem(200px);

    background-color: var(--mui-palette-common-white);
  }
}

.downloadAppsCard {
  @include medium-2-up {
    display: none;
  }
}

.downloadButtons {
  display: flex;
  gap: px-to-rem(16px);
  justify-content: center;
}

.loginButton {
  padding: px-to-rem(8px) px-to-rem(24px);

  font-weight: bold;

  &:hover {
    background-color: rgb(0 0 0 / 15%);
  }
}

.facebookCard {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include medium-2-up {
    min-height: px-to-rem(320px);
  }
}

.facebookButton {
  padding: px-to-rem(8px) px-to-rem(12px);

  font-weight: bold;
  font-size: px-to-rem(20px);
  line-height: 1.5;
}

.facebookDisclaimer {
  font-size: px-to-rem(14px);

  opacity: 0.75;
}
