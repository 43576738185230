@import '@/modules/ui/utils/mixins';

.footer {
  padding: px-to-rem(36px) px-to-rem(16px) px-to-rem(24px);

  border: 1px solid rgb(0 0 0 / 15%);

  background-color: var(--mui-palette-common-white);
}

.container {
  width: min(100%, var(--max-content-width));
  margin: auto;
}

.internalLinksRow {
  display: flex;
  gap: px-to-rem(24px);
  justify-content: space-between;
  align-items: flex-start;

  margin-bottom: px-to-rem(20px);

  @include medium-up {
    align-items: center;
  }
}

.navList {
  display: flex;
  flex-wrap: wrap;
  gap: px-to-rem(12px) px-to-rem(20px);

  margin: 0;
  padding: 0;

  list-style: none;

  @include medium-up {
    gap: px-to-rem(40px);
  }
}

.navLink {
  color: #34495e;
  font-weight: bold;
  font-size: px-to-rem(14px);
  text-transform: uppercase;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    color: var(--mui-palette-secondary-main);
  }
}

.socialAndAppsRow {
  display: flex;
  flex-wrap: wrap;
  gap: px-to-rem(38px);

  margin-bottom: px-to-rem(48px);
}

.socialLinks {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(10px);

  h3 {
    margin: 0;

    color: #929dad;
    font-weight: 700;
    font-size: px-to-rem(12px);
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: px-to-rem(12px);

    @include reset-list;
  }
}

.socialLinkItem {
  color: inherit;

  border: 2px solid #eff3f3;

  transition:
    transform 200ms ease-in-out,
    border-color 200ms ease-in-out;

  &:hover,
  &:focus-visible {
    border-color: transparent;

    transform: scale(1.25);
  }
}

.legalLinksRow {
  --list-gap: #{px-to-rem(20px)};

  display: flex;
  flex-wrap: wrap;
  gap: var(--list-gap);

  @include medium-up {
    --list-gap: #{px-to-rem(38px)};
  }

  ul {
    display: flex;
    gap: var(--list-gap);

    @include reset-list;
  }
}

.legalLink {
  color: #bfcad2;
  font-weight: 700;
  font-size: px-to-rem(12px);
  text-decoration: none;
}
