@import '@/modules/ui/utils/mixins';

.paypalButton {
  --variant-containedBg: #ffc439;
  --variant-containedColor: #000;

  &:disabled svg {
    opacity: 0.55;
    filter: grayscale(1);
  }

  &:hover {
    background-color: #f0b90b;
  }
}

.paypalIcon {
  transform: translateY(1px);
}
