.container {
  position: relative;

  display: inline-grid;
  place-items: center center;

  width: px-to-rem(30px);
  height: px-to-rem(30px);

  isolation: isolate;

  svg {
    grid-area: 1 / 1 / 2 / 2;

    position: relative;
    z-index: 2;
  }

  &::before {
    grid-area: 1 / 1 / 2 / 2;

    position: relative;
    content: '';
    z-index: 1;

    width: 60%;
    height: 60%;

    background-color: #fff;
  }
}
