@import '@/modules/ui/utils/mixins';

.form {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(32px);

  button[type='submit'] {
    width: min(100%, px-to-rem(200px));
  }
}

.select {
  text-align: left;
}

.checkboxWrapper {
  margin: px-to-rem(14px) 0;
}

.recaptcha {
  display: none;

  width: fit-content;
  margin: 0 auto px-to-rem(20px);
}

.visible {
  display: block;
}
