@import '@/modules/ui/utils/mixins';

.benefitsSection {
  display: grid;
  grid-template-areas: 'heading' 'list' 'member';
  gap: px-to-rem(16px);

  padding: px-to-rem(12px) px-to-rem(20px) px-to-rem(64px);

  @include small-2-up {
    grid-template-areas: 'heading heading' 'member list';
  }

  @include medium-up {
    padding-top: px-to-rem(20px);
  }

  @include large-up {
    // Hack to reduce the spacing between heading and list
    grid-template-areas: 'member heading' 'member list' 'member list' 'member list' 'member list' 'member list' 'member list' 'member list' 'member list';
    row-gap: 0;
    place-content: space-evenly;
    align-items: start;

    padding-top: px-to-rem(40px);
  }
}

.benefitsHeading {
  grid-area: heading;

  font-size: px-to-rem(26px);
  line-height: px-to-rem(54px);

  @include small-2-up {
    font-size: px-to-rem(30px);
  }

  @include medium-up {
    font-size: px-to-rem(40px);
  }

  @include large-up {
    font-size: px-to-rem(40px);
  }
}

.benefitsMember {
  grid-area: member;

  gap: px-to-rem(24px);
}

.benefitsList {
  grid-area: list;

  gap: px-to-rem(24px);

  padding: 0;
}

.benefitsListItem {
  gap: px-to-rem(24px);

  min-height: px-to-rem(84px);

  @include large-up {
    gap: px-to-rem(40px);
  }
}

.benefitsListTitle {
  font-weight: bold;
  line-height: px-to-rem(19px);
  text-transform: uppercase;

  @include large-up {
    font-size: px-to-rem(21px);
    line-height: px-to-rem(25px);
  }
}

.benefitsListImage {
  position: relative;

  flex-shrink: 0;

  width: px-to-rem(58px);
  height: px-to-rem(58px);

  @include small-2-up {
    width: px-to-rem(65px);
    height: px-to-rem(65px);
  }

  @include large-up {
    width: px-to-rem(85px);
    height: px-to-rem(85px);
  }

  & > img {
    object-fit: contain;
  }
}

.benefitsTestimony {
  display: none;

  @include small-2-up {
    display: inherit;

    max-width: px-to-rem(205px);

    font-size: px-to-rem(13px);
    line-height: px-to-rem(20px);
    text-align: center;
  }

  @include medium-up {
    max-width: px-to-rem(285px);

    font-size: px-to-rem(16px);
    line-height: px-to-rem(24px);
  }

  @include medium-up {
    max-width: px-to-rem(435px);
  }

  @include medium-up {
    max-width: px-to-rem(435px);
  }
}

.benefitsImageContainer {
  position: relative;

  width: px-to-rem(100px);
  height: px-to-rem(100px);

  border-radius: 50%;

  overflow: hidden;

  @include small-2-up {
    width: px-to-rem(200px);
    height: px-to-rem(200px);
  }

  @include medium-up {
    width: px-to-rem(270px);
    height: px-to-rem(270px);
  }

  @include large-up {
    width: px-to-rem(400px);
    height: px-to-rem(400px);
  }
}

.benefitsVerifyIcon {
  position: absolute;
  bottom: px-to-rem(10px);
  right: 0;

  color: var(--mui-palette-success-main);

  @include small-2-down {
    & > svg {
      width: px-to-rem(20px);
      height: px-to-rem(20px);
    }
  }

  @include small-2-up {
    right: px-to-rem(10px);

    & > svg {
      width: px-to-rem(40px);
      height: px-to-rem(40px);
    }
  }

  @include medium-up {
    bottom: px-to-rem(20px);

    & > svg {
      width: px-to-rem(50px);
      height: px-to-rem(50px);
    }
  }

  @include large-up {
    bottom: px-to-rem(30px);

    & > svg {
      width: px-to-rem(75px);
      height: px-to-rem(75px);
    }
  }
}

.benefitText {
  max-width: px-to-rem(320px);
}
