@import './functions';

@mixin x-small-up {
  @media (width >= #{px-to-em(320px)}) {
    @content;
  }
}

@mixin small-up {
  @media (width >= #{px-to-em(375px)}) {
    @content;
  }
}

@mixin small-2-down {
  @media (width < #{px-to-em(480px)}) {
    @content;
  }
}

@mixin small-2-up {
  @media (width >= #{px-to-em(480px)}) {
    @content;
  }
}

@mixin small-3-up {
  @media (width >= #{px-to-em(560px)}) {
    @content;
  }
}

@mixin medium-up {
  @media (width >= #{px-to-em(768px)}) {
    @content;
  }
}

@mixin medium-2-up {
  @media (width >= #{px-to-em(1024px)}) {
    @content;
  }
}

@mixin large-down {
  @media (width < #{px-to-em(1280px)}) {
    @content;
  }
}

@mixin large-up {
  @media (width >= #{px-to-em(1280px)}) {
    @content;
  }
}

@mixin large-2-up {
  @media (width >= #{px-to-em(1440px)}) {
    @content;
  }
}

@mixin x-large-up {
  @media (width >= #{px-to-em(1680px)}) {
    @content;
  }
}

@mixin x-large-2-up {
  @media (width >= #{px-to-em(1920px)}) {
    @content;
  }
}

@mixin max-up {
  @media (width >= #{px-to-em(2560px)}) {
    @content;
  }
}

@mixin reset-list {
  margin: 0;
  padding: 0;

  list-style: none;
}
