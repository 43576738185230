@import '@/modules/ui/utils/mixins';

.cardElement {
  flex-grow: 1;

  border: 1px solid var(--mui-palette-grey-300);
  border-radius: px-to-rem(4px);

  & > div {
    height: px-to-rem(35px);
  }
}

// Copied from recurly input element to keep consistency
.zip {
  width: px-to-rem(68px);
  height: px-to-rem(37px);
  padding: 0 px-to-rem(12px);
  padding-top: px-to-rem(2px);

  color: rgb(84 84 87);
  font-size: px-to-rem(13px);

  border: 1px solid var(--mui-palette-grey-300);
  border-radius: px-to-rem(4px);

  outline: none;

  &::placeholder {
    color: rgb(116 116 123);
  }
}
