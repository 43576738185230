@import '@/modules/ui/utils/mixins';

.menuContainer {
  left: 0 !important;

  width: 100vw;
  max-width: unset;

  border: 1px solid var(--mui-palette-grey-300);
  border-radius: 0;

  box-shadow: none;
}

.menuList {
  padding: 0;
}

.menuItem {
  padding-top: px-to-rem(12px);
  padding-bottom: px-to-rem(12px);

  &:not(&:last-child) {
    border-bottom: 1px solid var(--mui-palette-grey-300);
  }
}
