@import '@/modules/ui/utils/mixins';

.form {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(16px);
}

.rememberContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  display: flex;
  gap: px-to-rem(10px);
  align-items: center;

  margin: px-to-rem(20px) 0;

  &::before,
  &::after {
    content: '';

    flex: 1;

    height: px-to-rem(2px);

    background-color: #f7f7f7;
  }
}

.facebookButton {
  margin-bottom: px-to-rem(44px);

  background-color: #3b5998;
}

.noAccount {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(20px);
  align-items: center;

  button {
    width: px-to-rem(140px);
  }
}
