@import '@/modules/ui/utils/mixins';

.membershipVariant {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(8px);
  align-items: center;

  padding: px-to-rem(16px);

  &:not(&:last-child) {
    border-bottom: px-to-rem(2px) solid var(--mui-palette-info-200);
  }
}

.membershipPrice {
  font-weight: 700;
  font-size: px-to-rem(20px);
  line-height: px-to-rem(24px);
}

.membershipFrequency {
  font-weight: 700;
  font-size: 0.8rem;
  line-height: px-to-rem(24px);
  text-transform: uppercase;
}

.membershipButton {
  width: fit-content;

  font-size: px-to-rem(12px);
}
