/* stylelint-disable selector-class-pattern */
@import '@/modules/ui/utils/mixins';

.dialog {
  @include medium-up {
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.MuiDialog-paperWidthSm) {
      width: px-to-rem(500px);
    }
  }
}

.closeButton {
  position: absolute;
  top: px-to-rem(6px);
  right: px-to-rem(16px);
}
