@import '@/modules/ui/utils/mixins';

.card {
  width: 100%;
  padding: px-to-rem(20px);

  color: var(--mui-palette-text-primary);
  text-align: center;

  border-radius: px-to-rem(4px);

  background-color: var(--mui-palette-common-white);

  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);

  @include medium-2-up {
    width: px-to-rem(400px);
    padding: px-to-rem(20px) px-to-rem(60px);
  }
}

.headingOverride {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 px-to-rem(25px);

    font-weight: bold;
    font-size: px-to-rem(20px);
    line-height: 1.5;
  }
}
