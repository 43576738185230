@import '@/modules/ui/utils/mixins';

.container {
  display: flex;
  flex-direction: column;

  min-height: 100svh;
}

.main {
  display: flex;
  flex: 1;

  margin-top: px-to-rem(70px);
}

.desktopOnly {
  display: none;

  @include medium-up {
    display: inherit;
  }
}

.desktopDownOnly {
  display: inherit;

  @include medium-up {
    display: none;
  }
}
