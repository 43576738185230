@import '@/modules/ui/utils/mixins';

.skeletonContainer {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(8px);

  width: px-to-rem(320px);
}

.membershipContainer {
  width: px-to-rem(320px);
  padding: 0;
}

.membershipName {
  padding: px-to-rem(12px);

  color: var(--mui-palette-secondary-contrastText);
  font-size: 0.9rem;

  background: var(--mui-palette-secondary-main);
}

.membershipSummary {
  padding: px-to-rem(16px) px-to-rem(12px);

  color: var(--mui-palette-info-contrastText);
  font-size: 0.9rem;

  background: var(--mui-palette-info-light);
}

.skipButton {
  background-color: var(--mui-palette-common-background);
}

.activeMembershipContainer {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(16px);
  align-items: center;

  max-width: px-to-rem(400px);
  padding: px-to-rem(16px);
}

.activeMessage {
  font-weight: 700;
  font-size: px-to-rem(18px);
  line-height: px-to-rem(20px);
}

.cancelButton {
  width: fit-content;

  background-color: var(--mui-palette-common-background);
}
