@import '@/modules/ui/utils/mixins';

.container {
  display: flex;
  flex-direction: column;

  min-height: 100svh;
}

.main {
  position: relative;

  display: flex;
  flex: 1;
  flex-direction: column;

  margin-top: px-to-rem(70px);
}

.desktopOnly {
  display: none;

  @include medium-up {
    display: inherit;
  }
}
