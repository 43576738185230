@import '@/modules/ui/utils/mixins';

.header {
  min-height: px-to-rem(70px);

  background-color: var(--mui-palette-common-white);
}

.container {
  display: flex;
  justify-content: space-between;

  width: min(100%, var(--max-content-width));
  margin: auto;
  padding: 0 px-to-rem(16px);

  @include large-up {
    padding: 0;
  }
}

.rightGroup {
  display: flex;
  gap: px-to-rem(16px);
}

.navButtonLink {
  color: var(--mui-palette-text-primary);
}

.desktopOnly {
  display: none;

  @include medium-up {
    display: inherit;
  }
}
