@import '@/modules/ui/utils/mixins';

.content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  background-color: #f1efea;
}

.container {
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: px-to-rem(20px);

  font-size: px-to-rem(32px);
}
