@import '@/modules/ui/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: px-to-rem(16px);
  align-items: center;

  width: 100% !important;
  max-width: px-to-rem(420px);
  margin-top: px-to-rem(32px);
  padding: px-to-rem(32px) !important;
}

.confirmTitle {
  font-weight: 700;
  font-size: px-to-rem(28px);
  line-height: px-to-rem(32px);
}

.planInfo {
  font-weight: 700;
  font-size: px-to-rem(18px);
  line-height: px-to-rem(20px);
}

.paymentTitle {
  font-weight: 700;
  font-size: px-to-rem(24px);
  line-height: px-to-rem(28px);
}

.cancelButton {
  width: fit-content;
}
